import styled from 'styled-components'
import {
  H1,
  H3,
  InnerContent,
  InnerContentColumn,
  LinkText,
  P,
  Section,
  Subtitle,
} from '../../styles'
import Button from '../../components/Button'

// Headline

export const HeadlineContainer = styled(InnerContent)`
  margin-top: 60px;
  margin-bottom: 100px;

  @media (max-width: 1080px) {
    margin-top: 40px;
    margin-bottom: 60px;
  }
  @media (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 40px;
  }
`

export const HeadlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 510px;

  @media (max-width: 1080px) {
    max-width: unset;
  }
`

export const Headline = styled(H1)`
  margin-top: 20px;
`

export const HeadlineLinks = styled(Button)``

export const HeadlineLinksWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 15px;
  margin-bottom: 10px;
  @media (max-width: 1080px) {
    row-gap: 20px;
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    row-gap: 10px;
  }
`

export const HeadlineImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.bg};
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  //width: 100%;
`

export const HeadlineLottieWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 1080px) {
    display: none;
  }
`

// Offering

export const OfferingSection = styled(Section)`
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 40px;
  @media (max-width: 1080px) {
    align-items: flex-start;
  }
`
export const OfferingTitle = styled(H3)`
  text-align: center;
  max-width: 300px;

  @media (max-width: 1080px) {
    align-self: flex-start;
    text-align: left;
    margin-bottom: 10px;
  }
`

export const OfferingColumn = styled(InnerContentColumn)`
  align-items: center;
  background-color: ${(props) => props.theme.color.fun};
  border-radius: 10px;
  padding-bottom: 30px;
  @media (max-width: 1080px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    padding-bottom: 10px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const OfferingParagraph = styled(P)`
  text-align: center;
  max-width: 400px;
  @media (max-width: 1080px) {
    text-align: left;
  }
  @media (max-width: 767px) {
    max-width: unset;
    margin: 0 20px 10px;
  }
`
export const OfferingImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 1080px) {
    justify-content: flex-start;
    width: unset;
    padding: 20px;
  }
`
export const OfferingLottieWrapper = styled.div``

// Services

export const RegularSection = styled(Section)`
  padding-top: 40px;
  padding-bottom: 40px;
`

export const RegularHeader = styled(H3)`
  max-width: 300px;
`

export const ServicesButton = styled(Button)`
  margin-top: 30px;
`

// Reviews

export const ReviewsContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 60px;
  margin-bottom: 50px;
`
export const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ReviewerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: 10px;
`
export const ReviewsLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ReviewLink = styled(LinkText)`
  margin-top: 10px;
`

export const CompanyLogo = styled.img`
  height: 60px;
  width: 100%;
  object-fit: contain;
`

// Reasons
export const ReasonsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  row-gap: 30px;
`
export const Reason = styled.div`
  display: flex;
  flex-direction: column;
`
export const ReasonSubtitle = styled(Subtitle)`
  margin: 6px 0 10px;
`
export const ReasonParagraph = styled(P)`
  margin: 0;
`

export const FlagSpan = styled.span`
  margin-left: 10px;
`
