import {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {
  BrandLogo,
  InnerWrapper,
  LinkWrapper,
  LogoWrapper,
  MobileBrandLogoWrapper,
  MobileLinkWrapper,
  MobileMenuDialog,
  MobileMenuWrapper,
  MobileNavLink,
  StyledNavLink,
} from './styles'
import {LinkText, Section} from '../../styles'

import Logo from '../../assets/logo.svg'

const MainNavigation = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  useEffect(() => {
    if (isDialogOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isDialogOpen])

  const location = useLocation()
  const [isFunnyNavBg, setIsFunnyNavBg] = useState(false)

  useEffect(() => {
    const path = location.pathname
    if (path === '/offer' || path === '/about' || path === '/sustainability') {
      setIsFunnyNavBg(true)
    } else {
      setIsFunnyNavBg(false)
    }
    window.scroll(0, 0)
  }, [location])

  return (
    <>
      <Section bg={isFunnyNavBg ? 'fuuun' : ''}>
        <InnerWrapper>
          <LogoWrapper>
            <Link to="/">
              <BrandLogo src={Logo} alt="Doditex logo" width="170" height="52" loading="lazy" />
            </Link>
          </LogoWrapper>
          <LinkWrapper>
            <StyledNavLink to="/about">About us</StyledNavLink>
            <StyledNavLink to="/sustainability">Sustainability</StyledNavLink>
            <StyledNavLink to="/offer">What we offer</StyledNavLink>
          </LinkWrapper>
          <MobileMenuWrapper>
            <LinkText color="darkish" to="/" onClick={() => setIsDialogOpen(!isDialogOpen)}>
              {isDialogOpen ? 'Close' : 'Menu'}
            </LinkText>
          </MobileMenuWrapper>
        </InnerWrapper>
        {isDialogOpen && (
          <MobileMenuDialog onClick={() => setIsDialogOpen(!isDialogOpen)}>
            <MobileBrandLogoWrapper>
              <BrandLogo src={Logo} alt="Doditex logo" width="170" height="52" loading="lazy" />
            </MobileBrandLogoWrapper>
            <MobileLinkWrapper>
              <MobileNavLink to="/about" onClick={() => setIsDialogOpen(!isDialogOpen)}>
                About us
              </MobileNavLink>
              <MobileNavLink to="/sustainability" onClick={() => setIsDialogOpen(!isDialogOpen)}>
                Sustainability
              </MobileNavLink>
              <MobileNavLink to="/offer" onClick={() => setIsDialogOpen(!isDialogOpen)}>
                What we offer
              </MobileNavLink>
            </MobileLinkWrapper>
          </MobileMenuDialog>
        )}
      </Section>
    </>
  )
}

export default MainNavigation
