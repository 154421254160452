import React, {lazy, Suspense} from 'react'
import {Route, Routes, Outlet, useLocation} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
import theme from './theme'

import MainNavigation from './components/MainNavigation'

import Home from './pages/Home'
import About from './pages/generic/About'
import Sustainability from './pages/generic/Sustainability'

import {GlobalStyle, Loading} from './styles'
import Footer from './components/Footer'
import EmailUs from './components/EmailUs'
import NotFound from './components/NotFound'
import CookieBanner from './components/CookieBanner'

const LazyImpressum = lazy(() => import('./pages/legal/Impressum'))
const LazyTerms = lazy(() => import('./pages/legal/Terms'))
const LazyPrivacy = lazy(() => import('./pages/legal/Privacy'))
const LazyOurOffer = lazy(() => import('./pages/services/OurOffer'))
const LazyMaskPreviewGenerator = lazy(() => import('./pages/MaskPreviewGenerator'))

const App = () => {
  const location = useLocation()

  const locationsWithHeader = [
    '/',
    '/offer',
    '/impressum',
    '/terms',
    '/privacy',
    '/about',
    '/sustainability',
  ]
  const locationsWithFooter = [
    '/',
    '/offer',
    '/impressum',
    '/terms',
    '/privacy',
    '/about',
    '/sustainability',
  ]

  return (
    <>
      <GlobalStyle />

      <ThemeProvider theme={theme}>
        {locationsWithHeader.includes(location.pathname) && <MainNavigation />}
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/offer" element={<LazyOurOffer />} />
            <Route path="/impressum" element={<LazyImpressum />} />
            <Route path="/terms" element={<LazyTerms />} />
            <Route path="/privacy" element={<LazyPrivacy />} />
            <Route path="preview-generator" element={<LazyMaskPreviewGenerator />} />
            <Route path="/about" element={<About />} />
            <Route path="/sustainability" element={<Sustainability />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>

        {locationsWithFooter.includes(location.pathname) && <Footer />}
        {locationsWithHeader.includes(location.pathname) && <EmailUs />}
        <CookieBanner />
      </ThemeProvider>
      <Outlet />
    </>
  )
}

export default App
