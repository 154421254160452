import {Helmet} from 'react-helmet'

import {H3, P, Subtitle} from '../../../styles'
import {GenericSection, DescriptionSection} from '../styles'
import {Cert, CertWrapper} from './styles'

import Contact from '../../../components/Contact'

import GlobalOrganicTextileStandardLogo from '../../../assets/GlobalOrganicTextileStandardLogo.svg'
import BlendedOrganicCotton from '../../../assets/Organic100Logo.svg'
import OrganicFibers from '../../../assets/SoilAssociationLogo.svg'
import Repreve from '../../../assets/RepreveLogo.svg'
import Recycled from '../../../assets/GlobalRecycledStandardLogo.svg'

const Sustainability = () => {
  const certificates = [
    {
      name: 'Organic Cotton',
      imageType: 'svg',
      image: GlobalOrganicTextileStandardLogo,
    },
    {
      name: 'Blended Organic Cotton',
      imageType: 'svg',
      image: BlendedOrganicCotton,
    },
    {
      name: 'Organic Fibers',
      imageType: 'svg',
      image: OrganicFibers,
    },
    {
      name: 'Recycled',
      imageType: 'svg',
      image: Recycled,
    },
    {
      name: 'Repreve',
      imageType: 'svg',
      image: Repreve,
    },
  ]
  return (
    <>
      <Helmet>
        <title>Doditex - Sustainable sourcing</title>
      </Helmet>
      <GenericSection bg="fuuun">
        <H3 mb="80">Sustainable sourcing</H3>
      </GenericSection>
      <DescriptionSection>
        <P align="center" ml="10" mr="10">
          We are fully aware of the importance of sustainable production for our world. We,
          therefore, aim to meet customer requests for sustainably-sourced materials and products
          with reduced environmental impact from well-managed factories. As Doditex, we try to use
          sustainable materials in all areas we serve and support sustainable production. We
          manufacture sustainability certified fabrics in sustainability certified factories (sedex,
          bsci approved).
          <br />
          <br />
          In addition to materials made from ingredients that are produced from organic, recycled,
          and sustainable materials; garment (sewing), ironing, and packaging processes are also
          carried out in approved studios. We are aware of the importance of being confident that
          the products we deliver to the market meet consumers' expectations along with the required
          safety, regulatory, and industry standards.
          <br />
          <br />
          Sustainability certificates held by our factory partners involved in the production
          process have been inversely associated with complex social cohesion issues such as child
          labor, workers' wages, rights, health and safety, animal welfare, and environmentally
          harmful processing.
          <br />
          <br />
          In addition, as Doditex, we are completely against child labor and do not cooperate with
          companies that do not agree on this issue.
        </P>
      </DescriptionSection>
      <CertWrapper>
        {certificates.map((cert, index) => (
          <Cert id={cert.name} key={index}>
            <Subtitle mb="20">{cert.name}</Subtitle>
            <img src={cert.image} alt={cert.name} loading="lazy" />
          </Cert>
        ))}
      </CertWrapper>
      <Contact bg="fun" />
    </>
  )
}

export default Sustainability
