import {useEffect} from 'react'

import '../../libs/cookie-consent/cookieconsent.css'
import '../../libs/cookie-consent/cookieconsent.js'

const CookieBanner = () => {
  useEffect(() => {
    const cc = window.initCookieConsent()

    cc.run({
      autorun: true,
      current_lang: 'en',
      autoclear_cookies: true,
      page_scripts: true,

      onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once
      },

      onAccept: function (cookie) {
        // ... cookieconsent accepted
      },

      onChange: function (cookie, changed_preferences) {
        // ... cookieconsent preferences were changed
      },

      languages: {
        en: {
          consent_modal: {
            title: 'We use cookies',
            description:
              'Hi, this website uses tracking cookies to understand how you interact with it. They will be set only upon approval. <a aria-label="Privacy policy" class="cc-link" href="/privacy">Our privacy policy</a>',
            primary_btn: {
              text: 'Accept',
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Settings',
              role: 'settings', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Cookie preferences',
            save_settings_btn: 'Save settings',
            accept_all_btn: 'Accept all',
            reject_all_btn: 'Reject all', // optional, [v.2.5.0 +]
            cookie_table_headers: [
              {col1: 'Name'},
              {col2: 'Domain'},
              {col3: 'Expiration'},
              {col4: 'Description'},
              {col5: 'Type'},
            ],
            blocks: [
              {
                title: 'Cookie usage',
                description:
                  'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.',
              },
              {
                title: 'Analytics cookies',
                description:
                  'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.',
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  {
                    col1: '^_ga',
                    col2: 'google.com',
                    col3: '2 years',
                    col4: 'Visitor analytics',
                    col5: 'Permanent cookie',
                    is_regex: true,
                  },
                ],
              },
              {
                title: 'More information',
                description:
                  'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:hello@doditex.com">contact us</a>.',
              },
            ],
          },
        },
      },
    })
  }, [])

  return null
}

export default CookieBanner
