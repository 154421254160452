import React, {useState, useEffect, lazy, Suspense} from 'react'
import {Helmet} from 'react-helmet'

import Lottie from 'lottie-react'
import {BrowserView} from 'react-device-detect'

import {
  Headline,
  HeadlineWrapper,
  HeadlineContainer,
  HeadlineImageWrapper,
  HeadlineLinksWrapper,
  HeadlineLinks,
  HeadlineLottieWrapper,
} from './styles'
import {Section} from '../../styles'

const LazyContact = lazy(() => import('../../components/Contact'))
const LazyServices = lazy(() => import('./components/our-services'))
const LazyEnd2EndOffer = lazy(() => import('./components/end-to-end-offer'))
const LazyCustomerStories = lazy(() => import('./components/customer-stories'))
const LazySourcing = lazy(() => import('./components/sourcing'))

const Home = () => {

  const [animationHeadline, setAnimationHeadline] = useState()

  useEffect(() => {
    import('../../lotties/headline').then(setAnimationHeadline)
  }, [])

  return (
    <>
      <Helmet>
        <title>Doditex - Home</title>
      </Helmet>
      {/* Headline */}

      <Section>
        <HeadlineContainer>
          <HeadlineWrapper>
            <Headline color="dark">
              We start the supply of your fashion or textile products from Turkey and deliver it to
              your door!
            </Headline>
            <HeadlineLinksWrapper>
              <HeadlineLinks to="/offer" color="king">
                Check What We Offer
              </HeadlineLinks>
              <HeadlineLinks to="/about" color="darkish">
                Learn more about us
              </HeadlineLinks>
            </HeadlineLinksWrapper>
          </HeadlineWrapper>
          <BrowserView>
            <HeadlineImageWrapper>
              <HeadlineLottieWrapper>
                <Lottie animationData={animationHeadline} style={{height: '474px', width: "445px"}} />
              </HeadlineLottieWrapper>
            </HeadlineImageWrapper>
          </BrowserView>
        </HeadlineContainer>
      </Section>

      {/* Our end-to-end offering */}
      <Suspense fallback={<div>loading...</div>}>
        <LazyEnd2EndOffer />
      </Suspense>

      {/* Services */}
      <Suspense fallback={<div>loading...</div>}>
        <LazyServices />
      </Suspense>

      {/* Customer Stories */}
      {/* <Suspense fallback={<div>loading...</div>}>
        <LazyCustomerStories />
      </Suspense> */}

      {/* Sourcing */}
      <Suspense fallback={<div>loading...</div>}>
        <LazySourcing />
      </Suspense>

      {/* Contact */}
      <Suspense fallback={<div>loading...</div>}>
        <LazyContact />
      </Suspense>
    </>
  )
}

export default Home
