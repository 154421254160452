import styled from 'styled-components'
import Button from '../Button'
import {H3, P, InnerContent, Section, Subtitle} from '../../styles'

export const ContactSection = styled(Section)`
  //border-top: 1px solid ${(props) => props.theme.color.darkish};
`

export const InnerWrapper = styled(InnerContent)`
  margin-top: 40px;
  margin-bottom: 40px;
  @media (max-width: 1080px) {
    flex-direction: row;
    justify-content: space-between;
  }
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ContactTitle = styled(H3)`
  margin-bottom: 50px;
  width: 320px;
`

export const ContactButton = styled(Button)``

export const ContactLink = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: left;
`

export const BoldP = styled(Subtitle)`
  margin-top: 40px;
  margin-bottom: 15px;
`
export const ContactParagraph = styled(P)`
  margin-bottom: 10px;
`
export const IllustrationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 767px) {
    display: none;
  }
`
export const LottieWrapper = styled.div`
  width: 256px;
  height: 256px;
`
