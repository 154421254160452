import {NotFoundSection, StyledH3} from './styles'
import NotFoundIllustration from '../../assets/404.svg'
import Button from '../Button'

const NotFound = () => {
  return (
    <NotFoundSection>
      <img src={NotFoundIllustration} width="320" height="320" alt="Not found" loading="lazy" />
      <StyledH3>You don't belong here, human!</StyledH3>
      <Button to="/" color="king">
        Go home
      </Button>
    </NotFoundSection>
  )
}

export default NotFound
