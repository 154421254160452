import styled from 'styled-components'
import {NavLink} from 'react-router-dom'

export const InnerWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1084px;
  padding-top: 40px;
  z-index: 10;
`

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const BrandLogo = styled.img`
  display: block;
  z-index: 103;
`
export const LinkWrapper = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  @media (max-width: 767px) {
    display: none;
  }
`

export const MobileMenuWrapper = styled.div`
  display: none;
  z-index: 102;

  @media (max-width: 767px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2;
  text-align: center;
  color: ${(props) => props.theme.color.darkish};
  &.active,
  &:hover {
    text-decoration: underline;
  }
`

export const MobileMenuDialog = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  padding-top: 40px;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 101;
`
export const MobileLinkWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20%;
`
export const MobileBrandLogoWrapper = styled(LogoWrapper)`
  padding-left: 5%;
`

export const MobileNavLink = styled(StyledNavLink)`
  margin-left: 0;
  margin-bottom: 20px;
  &.active,
  &:hover {
    text-decoration: none;
  }
`
