import styled from 'styled-components'
import {Section} from '../../../styles'

export const CertWrapper = styled(Section)`
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
`
export const Cert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  margin: 10px 0;
  background-color: ${(props) => props.theme.color.boring};
  width: 532px;
  @media (max-width: 767px) {
    width: 100%;
  }
`
