import Lottie from 'lottie-react'
import animationData from '../../lotties/contact'
import {LinkText} from '../../styles'
import {
  BoldP,
  ContactButton,
  ContactLink,
  ContactParagraph,
  ContactSection,
  ContentWrapper,
  ContactTitle,
  IllustrationContainer,
  InnerWrapper,
  LottieWrapper,
} from './styles'

const Contact = ({bg}) => {
  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  }
  return (
    <ContactSection id="contact" bg={bg ? bg : 'fun'}>
      <InnerWrapper>
        <ContentWrapper>
          <ContactTitle color="dark">Have any questions? Let's connect!</ContactTitle>
          <ContactButton to="mailto:hello@doditex.com" ext="true" color="king">
            Send us an email
          </ContactButton>
          <BoldP color="darkish">or drop by if you are around</BoldP>
          <ContactParagraph color="darkish">Doditex Tekstil San. Tic. Ltd. Şti.</ContactParagraph>
          <ContactParagraph color="darkish">
            Esentepe Mh. Talatpaşa Cd. No: 5/1
            <br />
            34337 Şişli / Istanbul / Turkey
          </ContactParagraph>
          <ContactParagraph color="darkish">Phone: +90 212 706 54 36</ContactParagraph>
          <ContactLink
            color="darkish"
            ext="true"
            arrow={false}
            to="https://goo.gl/maps/LGqgc5NgKM2jT3ni7"
          >
            Find us on the map
          </ContactLink>
        </ContentWrapper>
        <IllustrationContainer>
          <LottieWrapper>
            <Lottie style={{height: '256px'}} animationData={animationData} />
          </LottieWrapper>
        </IllustrationContainer>
      </InnerWrapper>
    </ContactSection>
  )
}

export default Contact
