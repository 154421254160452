import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {ReactComponent as ArrowIcon} from '../../assets/arrow.svg'

export const StyledButton = styled(Link)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: left;
  text-decoration: underline;
  color: ${(props) => props.theme.color[props.$color]};
  @media (max-width: 767px) {
    font-size: 22px;
  }
`

export const StyledA = styled.a`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: left;
  text-decoration: underline;
  color: ${(props) => props.theme.color[props.$color]};
  @media (max-width: 767px) {
    font-size: 22px;
  }
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin-left: 15px;
`

export const Arrow = styled(ArrowIcon)`
  fill: ${(props) => props.theme.color[props.$color]};
`
