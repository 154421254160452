import {Arrow, IconContainer, StyledA, StyledButton} from './styles'

const Button = ({color, children, to, arrow, ext, ...props}) => {
  return ext ? (
    <StyledA href={to} target="_blank" $color={color} {...props}>
      {children}
      {arrow === false ? (
        ''
      ) : (
        <IconContainer>
          <Arrow title="Arrow icon" $color={color} height="100%" width="100%" />
        </IconContainer>
      )}
    </StyledA>
  ) : (
    <StyledButton to={to} $color={color} {...props}>
      {children}
      <IconContainer>
        <Arrow title="Arrow icon" $color={color} height="100%" width="100%" />
      </IconContainer>
    </StyledButton>
  )
}

export default Button
