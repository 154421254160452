import {Helmet} from 'react-helmet'

import {H3, P, Subtitle} from '../../../styles'
import {GenericSection, DescriptionSection, CeoComment, CeoCommentBox} from '../styles'

import {ReactComponent as MaskeLogo} from '../../../assets/maskeLogo.svg'

import CatSvg from '../../../assets/cats.svg'

import Contact from '../../../components/Contact'

const About = () => {
  return (
    <>
      <Helmet>
        <title>Doditex - About us</title>
      </Helmet>
      <GenericSection bg="fuuun">
        <H3 mb="40">Hello from the city of cats</H3>
        <img src={CatSvg} alt="Catstanbul" loading="lazy" />
      </GenericSection>
      <DescriptionSection>
        <P align="center">
          We are a supplier of fashion and textile products with a focus on the principles of
          sustainability. We assume the role of a consultant supplier in every process from proto
          sampling to bulk production, production preparation, production follow-up, packaging, and
          shipping. Also, we offer a wide range of product categories in men, women, and children by
          developing strong ventures with different factories and fabric mills in different
          countries all around the World. We have a wide range of categories like jerseys, knits,
          denim, underwear, and activewear and provides the most suitable materials to fulfill our
          customers' requests. We carefully follow the stages of Research & Development, Costing &
          Price Negotiation, Orders Placement & Follow Up, Quality Control.
          <br />
          <br />
          Thanks to our +15 years of experience, we devotedly listen to customers' requests and try
          to offer the best solution for them to achieve their dream products. Today, we are a
          global business partner for major retailers and brands in Turkey, the United Kingdom,
          Spain, France, and Germany. With our offices in Bangladesh, Munich and Istanbul, we work
          in a wide variety of ways to supply abroad. We offer products made of many different
          fabrics and materials.
          <br />
          <br />
          Today, many global customers receive service from our company in terms of product supply
          and design. With bits of help of our IT team, which provides software support to speed up
          our procurement processes, many processes are carried out quickly and easily.
        </P>
        <CeoCommentBox>
          <CeoComment align="center" mb="20">
            “Sustainability is at the core of our company. Since our establishment, we have not only
            aimed to provide products with a sustainability focus, but also have sustainable
            business relations with our customers.”
          </CeoComment>
          {/* <MaskeLogo width="56" height="56" /> */}
          <Subtitle mt="10" align="center">
            Caner Badur
          </Subtitle>
          <P align="center">Founder</P>
        </CeoCommentBox>
      </DescriptionSection>
      <Contact bg="fun" />
    </>
  )
}

export default About
