import styled from 'styled-components'
import {P, Section} from '../../styles'

export const GenericSection = styled(Section)`
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
`
export const DescriptionSection = styled(Section)`
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 5%;
`
export const CeoCommentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding: 20px;
  max-width: 560px;
  background-color: ${(props) => props.theme.color.fuuun};
`
export const CeoComment = styled(P)`
  font-style: italic;
`
