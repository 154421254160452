import styled from 'styled-components'
import {H3, Section} from '../../styles'

export const NotFoundSection = styled(Section)`
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100vw;
`

export const StyledH3 = styled(H3)`
  text-align: center;
  max-width: 320px;
  margin-top: 30px;
`
