import styled, {createGlobalStyle} from 'styled-components'
import {Link} from 'react-router-dom'

export const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Inter', 'Arial', sans-serif;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialised;
        text-rendering: optimizeLegibility
    }
`
export const Section = styled.section`
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props.theme.color[props.bg] ? props.theme.color[props.bg] : props.theme.color.bg};
  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
    padding-right: 5%;
    //width: 100%;
  }
`
export const InnerContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  max-width: 1084px;
  width: 100%;
  margin: 0 5%;

  @media (max-width: 1080px) {
    grid-template-columns: unset;
    column-gap: unset;
    display: flex;
    flex-direction: column;
    margin: unset;
    max-width: unset;
  }
`

export const InnerContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`

export const A = styled.a`
  color: ${(props) => props.theme.color.dark};
  text-decoration: none;
`

export const H1 = styled.h1`
  font-size: 42px;
  line-height: 54px;
  font-weight: 400;
  color: ${(props) => props.theme.color[props.color]};
  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 42px;
  }
`
export const H3 = styled.h3`
  font-size: 32px;
  line-height: 42px;
  font-weight: 500;
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : 0)};
  margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : 30)};
  color: ${(props) => props.theme.color[props.color]};
`
export const P = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : 0)};
  margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : 0)};
  margin-right: ${(props) => (props.mr ? `${props.mr}px` : 0)};
  margin-left: ${(props) => (props.ml ? `${props.ml}px` : 0)};
  color: ${(props) => props.theme.color[props.color]};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  max-width: 560px;
  word-break: break-word;
`
export const Subtitle = styled(P)`
  font-weight: 700;
`

export const Caption = styled(P)`
  font-size: 12px;
  line-height: 20px;
`

export const CaptionLink = styled.a`
  font-weight: 700;
  text-decoration: none;
`

export const LinkText = styled(Link)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${(props) => props.theme.color[props.color]};
`

export const LinkExternal = styled.a`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${(props) => props.theme.color[props.color]};
`

export const PlainPageContaier = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 1084px;
  padding: 100px 0;
  margin: 0 5%;

  @media (max-width: 1080px) {
    width: 100%;
    margin: 0;
    padding: 60px 0;
  }
`

export const Loading = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 12;
  background-color: ${(props) => props.theme.color.fun};
`
