import {FooterInnerWrapper, FooterSection, LinksWrapper, Links} from './styles'
import {P} from '../../styles'

const Footer = () => (
  <FooterSection bg="fun">
    <FooterInnerWrapper>
      <P color="darkish">Copyright © 2022 Doditex. All rights reserved.</P>
      <LinksWrapper>
        <Links to="/impressum" color="darkish">
          Impressum
        </Links>
        <Links to="/terms" color="darkish">
          Terms of use
        </Links>
        <Links to="/privacy" color="darkish">
          Privacy
        </Links>
      </LinksWrapper>
    </FooterInnerWrapper>
  </FooterSection>
)

export default Footer
