import styled from 'styled-components'

export const BubbleContainer = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 11;
  bottom: 40px;
  right: 30px;
  @media (max-width: 640px) {
    bottom: 20px;
    right: 20px;
  }
`

export const Bubble = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.color.fuuun};
  border: 2px solid black;
  border-radius: 30px;
  width: 56px;
  height: 56px;
  box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.2);
  &:hover {
    cursor: pointer;
    padding: 0 11px;
    width: auto;
    justify-content: flex-start;
  }
`
export const BubbleMobileToggled = styled(Bubble)`
  padding: 0 11px;
  width: auto;
  justify-content: flex-start;
`

export const BubbleMobileClose = styled(Bubble)`
  width: 40px;
  height: 40px;
  margin-right: 6px;
`
