const theme = {
  color: {
    king: '#8A529D',
    kong: '#0918f0',
    disabled: '#767676',
    dark: '#000',
    darkish: 'rgba(0, 0, 0, 0.82)',
    boring: 'rgba(201, 159, 159, 0.1)',
    fuuun: '#f4decb',
    fun: '#F7E7DC',
    white: '#ffffff',
    bg: '#F8EEE7',
  },
}

export default theme
