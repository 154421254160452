import {useState} from 'react'
import {BrowserView, MobileView} from 'react-device-detect'

import {Bubble, BubbleContainer, BubbleMobileClose, BubbleMobileToggled} from './styles'
import {ReactComponent as EmailIcon} from '../../assets/email.svg'
import {ReactComponent as CloseIcon} from '../../assets/close.svg'

import {A, P} from '../../styles'

const EmailUs = () => {
  const [hovered, setHovered] = useState(false)
  const [bubbleToggled, setBubbleToggled] = useState(false)

  return (
    <>
      <BrowserView>
        <A href={'mailto:hello@doditex.com'}>
          <BubbleContainer>
            <Bubble onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
              <EmailIcon />
              {hovered && (
                <P ml="10" mr="10">
                  Email us at hello@doditex.com
                </P>
              )}
            </Bubble>
          </BubbleContainer>
        </A>
      </BrowserView>
      <MobileView>
        {true && (
          <BubbleContainer>
            <Bubble onClick={() => setBubbleToggled(true)}>
              <EmailIcon />
            </Bubble>
          </BubbleContainer>
        )}
        {bubbleToggled && (
          <BubbleContainer>
            <BubbleMobileClose onClick={() => setBubbleToggled(false)}>
              <CloseIcon />
            </BubbleMobileClose>
            <A href={'mailto:hello@doditex.com'}>
              <BubbleMobileToggled>
                <P ml="10" mr="10">
                  Email us at hello@doditex.com
                </P>
              </BubbleMobileToggled>
            </A>
          </BubbleContainer>
        )}
      </MobileView>
    </>
  )
}

export default EmailUs
